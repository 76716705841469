import React from 'react';
import { Router } from "@reach/router";
import styled from 'styled-components';

import routes from '../../routes';
import { IS_PRODUCTION } from '../../config';
import { useApi } from '../../api';
import { getImageDataFromMediaObject } from '../../utils/assets';

import Home from '../Home';
import Article from '../Article';
import Articles from '../Articles';
import Search from '../Search';

import { Helmet } from '../../components/Helmet';
import { Footer, FooterSection } from '../../components/Footer';
import { Nav } from '../../components/Nav';
import { LiveChat } from '../../components/LiveChat';
import { Markdown } from '../../components/Markdown';
import { Link } from '../../components/Link';
import { Li, Ol } from '../../components/List';
import { Cookie, CookieInner, CookieButton } from '../../components/Cookie';
import Preview from '../Preview';
import TagManager from 'react-gtm-module';

const SocialMediaList = styled(Ol)`
  margin: 0;
  padding: 2rem 0 0;
  display: flex;
  flex-direction: row;
  list-style-type: none;
  align-items: center;
  flex-wrap: wrap;
`;

const SocialMediaItem = styled(Li)`
  margin: 0 3rem 0 0;
  &:last-child {
    margin-right: 0;
  }
`;

const SocialMediaImage = styled.img`
  display: block;
  max-width: 2.6rem;
`;

const AppRouter = styled(Router)`
  flex-grow: 1;
`;

const Container = ({ children }) => {
  return (
    <React.Fragment>
      <Nav />
      {children}
    </React.Fragment>
  )
}



const App = ({ className }) => {

  const { api, footer, socialMediaOrdered, liveChat, siteConfig } = useApi();

  React.useEffect(() => {
    api.articles(200);
    api.topics(50);
    api.footer();
    api.socialMedia();
    api.homepageSplash();
    api.topicsSplash();
    api.liveChatSettings();
    api.welcomeBroadcast();
    api.siteConfigSettings();
    TagManager.initialize({ gtmId: 'GTM-WHR9NPP' });
  }, []);

  console.info('lse welcome');

  React.useEffect(() => {
   console.info('HI!');
  }, [Router]);
  return (
    <div className={className}>
      <Helmet>
        <title>{siteConfig.title}</title>

      </Helmet>
      <Router>
        <Cookie path="/*" /> 
        <span path="/my-city/*"></span>
       </Router>
      <AppRouter>
        <Container path="/">
          <Home path={routes.HOME} />
          <Articles path={routes.ARTICLES} />
          <Article path={routes.ARTICLE} />
          <Search path={routes.SEARCH} />
          <Preview path={routes.PREVIEW} />
        </Container>
      </AppRouter>
      {footer && (
        <Footer>
          {footer.composer.map((item, i) => (
            <FooterSection key={`footer-${item.type}-${i}`}>
              <Markdown source={item.value} />
            </FooterSection>
          ))}
          {socialMediaOrdered.length && (
            <SocialMediaList>
              {socialMediaOrdered.map(({ name, fullURL, socialMediaIcon = {}, sys }) => {
                const img = getImageDataFromMediaObject(socialMediaIcon);
                return (
                  <SocialMediaItem key={sys.id}>
                    <Link href={fullURL} title={name}>
                      <SocialMediaImage src={img.src} alt={img.alt} />
                    </Link>
                  </SocialMediaItem>
                )
              })}
            </SocialMediaList>
          )}
        </Footer>
      )}
      {IS_PRODUCTION && liveChat && <LiveChat />}
      
      {/* <Cookie /> */}
    </div>
  );
};

export default styled(App)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${Cookie} {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 4;
  } 

  ${({ theme }) => theme.media.charlie`
    ${CookieInner} {
      flex-direction: column;
      align-items: flex-start;
    }
    ${CookieButton} {
      margin-left: 0;
      margin-top: 2rem;
    }
  `}
`;
