import React from 'react';
import styled from 'styled-components';

import { P } from '../Text';
import { containerStyles } from '../../styles';

export const FooterInner = styled.div``;

export const FooterSection = styled.section`

`;

export const Footer = styled(({ className, children, ...props }) => (
  <footer className={className} {...props}>
    <FooterInner>
      {children}
    </FooterInner>
  </footer>
))`
  padding: 4rem 0;
  background-color: ${({ theme }) => theme.colors.kashyyk};

  ${P} {
    margin-bottom: 0;
  }

  ${FooterInner} {

    ${containerStyles}

    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 2rem;

    ${({ theme }) => theme.media.charlie`
      grid-template-columns: 1fr;
    `}
  }

  ${FooterSection} {

  }
`;
