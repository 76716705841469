import React from 'react';
import styled from 'styled-components';

import { saveCookie, loadCookie } from '../../cache';

import { P } from '../Text';
import { Button } from '../Button';
import { Link } from '../Link';

export const cookieUrl = 'https://www.lse.ac.uk/lse-information/cookies.aspx';

export const CookieWrapper = styled.aside`
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.coruscant};
  color: ${({ theme }) => theme.colors.naboo};
`;

export const CookieInner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CookieButton = styled(Button)``;

export const Cookie = styled(({ className }) => {

  const cachedCookiesEnabled = loadCookie();
  const [cookiesEnabled, setCookiesEnabled] = React.useState(cachedCookiesEnabled);

  const onAcceptCookies = () => {
    saveCookie(true);
    setCookiesEnabled(true);
  }

  const styles = {};
  if (cookiesEnabled) {
    styles.opacity = 0;
    styles.pointerEvents = 'none';
  }

  return (
    <CookieWrapper className={className} style={styles}>
      <CookieInner>
        <P>We use cookies on this website to make your browsing experience better. By continuing to use this website, you agree to <Link href={cookieUrl}>our cookie policy</Link>.</P>
        <CookieButton onClick={onAcceptCookies}>Accept and continue</CookieButton>
      </CookieInner>
    </CookieWrapper>
  );
})`
  transition: 0.2s ease opacity;
  opacity: 1;

  ${P} {
    margin-bottom: 0;
  }

  ${CookieButton} {
    color: inherit;
    margin-left: 2rem;
  }
`;
